import styled, { css } from "styled-components";

const getStylesBySize = (size: number) => {
  let fz = 16;
  let lh = 24;

  switch (size) {
    case 1:
      fz = 10;
      lh = 15;
      break;
    case 2:
      fz = 12;
      lh = 16;
      break;
    case 3:
      fz = 14;
      lh = 19;
      break;
    case 5:
      fz = 18;
      lh = 25;
      break;
    case 6:
      fz = 20;
      lh = 27;
      break;
    default:
      fz = 16;
      lh = 24;
      break;
  }

  return `
    font-size: ${fz}px;
    line-height: ${lh}px;
  `;
};

export const textStyles = css`
  display: ${({ display }: any) => display || "block"};
  ${({ size }: any) => (size ? getStylesBySize(size) : getStylesBySize(4))}
  color: ${({ color }: any) => (color ? `var(--color-${color})` : "var(--color-gray-900)")};
  ${({ nonStandartColor }: any) => nonStandartColor && `color: ${nonStandartColor};`}
  margin: ${({ margin }: any) => margin || "0"};
  ${({ lineHeight }: any) =>
    lineHeight &&
    css`
      line-height: ${lineHeight};
    `}
  ${({ fontSize }: any) =>
    fontSize &&
    css`
      font-size: ${fontSize};
    `}
  font-weight: ${({ fontWeight }: any) => fontWeight || 400};
`;

interface SpanProps {
  color?:
    | "gray-100"
    | "gray-200"
    | "gray-300"
    | "gray-400"
    | "gray-500"
    | "gray-600"
    | "gray-700"
    | "gray-800"
    | "gray-900"
    | "gray-1"
    | "gray-2"
    | "gray-3"
    | "gray-4"
    | "gray-5"
    | "gray-6"
    | "gray-7"
    | "gray-8"
    | "gray-9"
    | "gray-10"
    | "gray-11"
    | "gray-12"
    | "gray-13"
    | "green-700"
    | "main-700"
    | "SecondHeader"
    | "DarkHeader"
    | "red-500";
  nonStandartColor?: string;
  margin?: string;
  lineHeight?: string;
  fontWeight?: 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900;
  fontSize?: string;
  size?: 1 | 2 | 3 | 4 | 5 | 6;
  display?: "block" | "inline";
}

/**
 *
 * @param {number} size
 * default: 4;
 * 1 - font-size: 10px, line-height: 15px
 * 2 - font-size: 12px, line-height: 18px
 * 3 - font-size: 14px, line-height: 21px
 * 4 - font-size: 16px, line-height: 24px
 * 5 - font-size: 18px, line-height: 25px
 * 5 - font-size: 20px, line-height: 27px
 *
 * @param {string} color - default: gray-900;
 * @param {string} margin - default: 0; Must be specified like this "0 0 16px 0"
 * @param {number} fontWeight - default: 400;
 * @param {string} lineHeight - default: 24px; Must be used if you need a custom line-height
 * @param {string} fontSize - default: 16px; Must be used if you need a custom font-size
 *
 */

export const Text = styled.span<SpanProps>`
  ${textStyles}
`;
