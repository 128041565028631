import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import PaymentModel from "../../../viewModels/Payments/PaymentModel";
import SortObject from "../../../types/SortObject";
import { DealAccountModel } from "../../../viewModels/Deal/DealModel";
import { DealAccountStatus, DealAccountType } from "../../../dto/Deal/DealDto";

export type AccountsFilters = {
  type: Array<DealAccountType> | null;
  currencyId: Array<number> | null;
  status: Array<DealAccountStatus> | null;
};

const initialState: {
  loading: boolean;
  loadingData: boolean;
  page: number;
  pageSize: number;
  payments?: Array<PaymentModel>;
  total?: number;
  sort?: SortObject;

  accountsModalOpen: boolean;
  accountsLoading: boolean;
  accountsLoadingData: boolean;
  accountsPage: number;
  accountsPageSize: number;
  accounts?: Array<DealAccountModel>;
  accountsTotal?: number;
  accountsSort?: SortObject;
  accountsFilters?: AccountsFilters;
} = {
  loading: true,
  loadingData: true,
  page: undefined!,
  pageSize: 10,

  accountsModalOpen: false,
  accountsLoading: true,
  accountsLoadingData: true,
  accountsPage: undefined!,
  accountsPageSize: 10,
};

const accountantPaymentsSlice = createSlice({
  name: "accountantPayments",
  initialState,
  reducers: {
    loadPayments(state) {
      state.loadingData = true;
      state.page = 1;
    },
    loadPaymentsSuccess(state, action: PayloadAction<{ payments: Array<PaymentModel>; total: number }>) {
      const { payments, total } = action.payload;

      state.loading = false;
      state.loadingData = false;
      state.payments = payments;
      state.total = total;
    },

    changeTable(state, action: PayloadAction<{ page: number; pageSize: number; sort?: SortObject }>) {
      const { page, pageSize, sort } = action.payload;
      state.loadingData = true;
      state.page = page;
      state.pageSize = pageSize;
      state.sort = sort;
    },

    openAccountsModal(state, action: PayloadAction<{ filters: AccountsFilters }>) {
      state.accountsModalOpen = true;
      state.accountsLoading = true;
      state.accountsLoadingData = true;
      state.accountsPage = 1;
      state.accountsFilters = action.payload.filters;
    },

    closeAccountsModal(state) {
      state.accountsModalOpen = false;
      state.accounts = undefined;
      state.accountsTotal = undefined;
    },

    loadAccountsSuccess(state, action: PayloadAction<{ accounts: Array<DealAccountModel>; total: number }>) {
      const { accounts, total } = action.payload;

      state.accountsLoading = false;
      state.accountsLoadingData = false;
      state.accounts = accounts;
      state.accountsTotal = total;
    },

    changeAccountsTable(state, action: PayloadAction<{ page: number; pageSize: number; sort?: SortObject; filters: AccountsFilters }>) {
      const { page, pageSize, sort, filters } = action.payload;
      state.accountsLoadingData = true;
      state.accountsPage = page;
      state.accountsPageSize = pageSize;
      state.accountsSort = sort;
      state.accountsFilters = filters;
    },
  },
});

export default accountantPaymentsSlice;
