import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { SupportedLanguages } from "../../viewModels/Settings/TranslationModel";

const initialState: {
  loading: boolean;
  loadingData: boolean;

  translations: { [key in SupportedLanguages]: { [key: string]: string } };
  keys: Array<string>;
  selectedKeys: Array<string>;
} = {
  loading: false,
  loadingData: false,

  translations: undefined!,
  keys: undefined!,
  selectedKeys: [],
};

const translationsSlice = createSlice({
  name: "translations",
  initialState,
  reducers: {
    loadTranslations(state) {
      state.loadingData = true;
    },

    setLoadedTranslations(state, action: PayloadAction<{ [key in SupportedLanguages]: { [key: string]: string } }>) {
      state.loadingData = false;
      state.translations = action.payload;
      state.keys = Object.keys(action.payload.ru);
    },

    changeTranslation(state, action: PayloadAction<{ lang: SupportedLanguages; key: string; value: string }>) {
      const { lang, key, value } = action.payload;

      if (!state.keys.includes(key)) {
        console.warn(`Key ${key} not found in key list`);
      } else {
        state.translations[lang][key] = value;
      }
    },

    addKey(state, action: PayloadAction<string>) {
      state.translations.ru[action.payload] = "";
      state.translations.en[action.payload] = "";
      state.translations.zh[action.payload] = "";
      state.keys.push(action.payload);
    },

    removeKey(state, action: PayloadAction<string>) {
      delete state.translations.ru[action.payload];
      delete state.translations.en[action.payload];
      delete state.translations.zh[action.payload];
      state.keys.splice(state.keys.indexOf(action.payload), 1);
    },

    selectRows(state, action: PayloadAction<Array<string>>) {
      state.selectedKeys = action.payload;
    },

    updateTranslations(state) {
      state.loadingData = true;
    },
  },
});

export default translationsSlice;
