import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ApplicationModel, ApplicationStatuses } from "../../../viewModels/Applications/ApplicationModel";
import { UserBasicInfoModel } from "../../../viewModels/User/UserBasicInfoModel";
import { ApplicationFilterItems } from "../../../components/V2Components/ApplicationFilters";

const initialState: {
  loadingData: boolean;
  page: number;
  pageSize: number;

  status: ApplicationStatuses;

  applications?: Array<ApplicationModel>;
  selectedApplicationIds?: Array<number>;
  total?: number;

  sales?: Array<UserBasicInfoModel>;

  filters: ApplicationFilterItems;
} = {
  loadingData: false,

  page: undefined!,
  pageSize: 10,
  status: undefined!,

  filters: {},
};

const salesHomeSlice = createSlice({
  name: "salesHome",
  initialState,
  reducers: {
    createFtlApplication(
      state,
      // eslint-disable-next-line no-unused-vars
      action: PayloadAction<{
        fromLocationId: number;
        waypointId?: number;
        toLocationId: number;
        date?: string;
        transportCharacteristicId: number;
        count: number;
        cost: number;
        currencyId: number;
      }>,
    ) {
      state.loadingData = true;
    },
    createFclApplication(
      state,
      // eslint-disable-next-line no-unused-vars
      action: PayloadAction<{
        fromLocationId: number;
        waypointId?: number;
        toLocationId: number;
        date?: string;
        transportCharacteristicId: number;
        transportCharacteristicValue: string;
        count: number;
        cost: number;
        currencyId: number;
      }>,
    ) {
      state.loadingData = true;
    },
    createApplication(
      state,
      // eslint-disable-next-line no-unused-vars
      action: PayloadAction<{
        transportCharacteristicId: number;
      }>,
    ) {
      state.loadingData = true;
    },
    createApplicationSuccess(state) {
      state.loadingData = false;
    },
    loadApplications(state, action: PayloadAction<ApplicationStatuses>) {
      state.loadingData = true;
      state.page = 1;
      state.status = action.payload;
    },
    loadApplicationsSuccess(state, action: PayloadAction<{ applications?: Array<ApplicationModel>; total: number }>) {
      const { applications, total } = action.payload;

      state.loadingData = false;
      state.applications = applications;
      state.total = total;
    },

    changePage(state, action: PayloadAction<number>) {
      state.loadingData = true;
      state.page = action.payload;
    },

    selectApplication(state, action: PayloadAction<number>) {
      if (!state.selectedApplicationIds) {
        state.selectedApplicationIds = [action.payload];
      } else {
        state.selectedApplicationIds.push(action.payload);
      }
    },
    unselectApplication(state, action: PayloadAction<number>) {
      if (state.selectedApplicationIds) {
        const index = state.selectedApplicationIds.indexOf(action.payload);
        state.selectedApplicationIds.splice(index, 1);

        if (state.selectedApplicationIds.length === 0) {
          state.selectedApplicationIds = undefined;
        }
      }
    },
    selectAllApplication(state, action: PayloadAction<Array<number>>) {
      if (!state.selectedApplicationIds) {
        state.selectedApplicationIds = action.payload;
      } else {
        state.selectedApplicationIds = Array.from(new Set(state.selectedApplicationIds.concat(action.payload)));
      }
    },
    unselectAllApplication(state, action: PayloadAction<Array<number>>) {
      action.payload.forEach((x) => {
        if (state.selectedApplicationIds) {
          const index = state.selectedApplicationIds.indexOf(x);
          state.selectedApplicationIds.splice(index, 1);
        }
      });
      if (state.selectedApplicationIds && state.selectedApplicationIds.length === 0) {
        state.selectedApplicationIds = undefined;
      }
    },
    resetSelectedApplications(state) {
      state.selectedApplicationIds = undefined;
    },
    deleteApplications(state) {
      state.loadingData = true;
    },
    deleteApplicationsSuccess(state) {
      state.selectedApplicationIds = undefined;
    },
    loadSalesSuccess(state, action: PayloadAction<Array<UserBasicInfoModel>>) {
      state.sales = action.payload;
    },

    updateApplication(
      state,
      // eslint-disable-next-line no-unused-vars
      action: PayloadAction<Omit<ApplicationModel, "type" | "creationDate" | "indicativeCreationDate" | "creator">>,
    ) {
      state.loadingData = true;
    },
    updateApplicationSuccess(state, action: PayloadAction<ApplicationModel>) {
      state.loadingData = false;
      const index = state.applications?.findIndex((x) => x.id === action.payload.id);
      if (index && state.applications) {
        state.applications[index] = action.payload;
      }
    },
    changeFilters(state, action: PayloadAction<ApplicationFilterItems>) {
      state.filters = action.payload;
      state.loadingData = true;
    },
    copyApplications(state) {
      state.loadingData = true;
    },
  },
});

export default salesHomeSlice;
