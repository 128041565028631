import { createSelector } from "@reduxjs/toolkit";

import USER_PERMISSIONS from "../../dto/User/UserPermissions";
import { ADDITIONAL_JOBS_TYPE_ENUM } from "../../dto/Settings/IAdditionalJobDto";
import { RootState } from "../../configureStore";

const selectGlobal = (state: RootState) => state.global;

const makeSelectLoading = () => createSelector(selectGlobal, (globalState) => globalState.loading);

const makeSelectInitialLoadingError = () => createSelector(selectGlobal, (globalState) => globalState.initialLoadingError);

const makeSelectUserInfo = () => createSelector(selectGlobal, (globalState) => globalState.userInfo);

const makeSelectIsShowCarrierInfo = () =>
  createSelector(
    selectGlobal,
    (globalState) =>
      globalState.userInfo &&
      globalState.userInfo.company.types.includes("FORWARDER") &&
      globalState.userInfo.role.permissions.includes(USER_PERMISSIONS.DICTIONARY_ROTE_VIEW),
  );

const makeSelectIsAuthModalVisible = () => createSelector(selectGlobal, (globalState) => globalState.isAuthModalVisible);

const makeSelectIsContactModalVisible = () => createSelector(selectGlobal, (globalState) => globalState.isContactModalVisible);

const makeSelectIsContactModalLoading = () => createSelector(selectGlobal, (globalState) => globalState.isContactModalLoading);

const makeSelectCurrencies = () => createSelector(selectGlobal, (globalState) => globalState.currencies);
const makeSelectCurrencyById = (id: number) => createSelector(makeSelectCurrencies(), (currencies) => currencies.find((x) => x.id === id)!);
const makeSelectCurrencySymbol = (id: number) =>
  createSelector(makeSelectCurrencies(), (currencies) => currencies.find((x) => x.id === id)?.symbol!);
const makeSelectCurrencyName = (id: number) =>
  createSelector(makeSelectCurrencies(), (currencies) => currencies.find((x) => x.id === id)?.name);
const makeSelectCurrenciesOptions = () =>
  createSelector(makeSelectCurrencies(), (currencies) => currencies.map((x) => ({ label: x.name, value: x.id })));

const makeSelectTransportCharacteristics = () => createSelector(selectGlobal, (globalState) => globalState.transportCharacteristics);
const makeSelectTransportCharacteristicById = (id: number) =>
  createSelector(makeSelectTransportCharacteristics(), (transportCharacteristics) => transportCharacteristics.find((x) => x.id === id)!);
const makeSelectTransportCharacteristicsOptions = () =>
  createSelector(makeSelectTransportCharacteristics(), (transportCharacteristics) =>
    transportCharacteristics.map((x) => ({ label: x.shortName, value: x.id })),
  );
const makeSelectContainerTypeOptions = () =>
  createSelector(makeSelectTransportCharacteristics(), (transportCharacteristics) =>
    transportCharacteristics.find((x) => x.type === "LIST")?.possibleValues?.map((x) => ({ label: x.value, value: x.id })),
  );

const makeSelectIsContainerTransportation = (id: number) =>
  createSelector(
    makeSelectTransportCharacteristics(),
    (transportCharacteristics) => transportCharacteristics.find((x) => x.id === id)?.loadType === "FCL",
  );

const makeSelectTransportationTypes = () => createSelector(selectGlobal, (globalState) => globalState.transportationTypes);
const makeSelectTransportationTypeTypeById = (id: number) =>
  createSelector(makeSelectTransportationTypes(), (transportationTypes) => transportationTypes.find((x) => x.id === id)?.type);
const makeSelectTransportationTypesByIds = (ids: Array<number>) =>
  createSelector(makeSelectTransportationTypes(), (transportationTypes) => transportationTypes.filter((x) => ids.indexOf(x.id) !== -1));
const makeSelectTransportationTypeNameById = (id: number) =>
  createSelector(makeSelectTransportationTypes(), (transportationTypes) => transportationTypes.find((x) => x.id === id)?.name);
const makeSelectTransportationTypesOptions = () =>
  createSelector(makeSelectTransportationTypes(), (transportationTypes) =>
    transportationTypes.map((x) => ({ label: x.name, value: x.id })),
  );

const makeSelectAdditionalJobs = () => createSelector(selectGlobal, (globalState) => globalState.additionalJobs);
const makeSelectContainerIssuingAdditionalJob = () =>
  createSelector(
    makeSelectAdditionalJobs(),
    (additionalJobs) => additionalJobs.find((x) => x.type === ADDITIONAL_JOBS_TYPE_ENUM.CONTAINER_ISSUING)!,
  );

const makeSelectDocumentTypes = () => createSelector(selectGlobal, (globalState) => globalState.documentTypes);
const makeSelectDocumentTypeById = (id: number) => createSelector(makeSelectDocumentTypes(), (types) => types.find((x) => x.id === id)!);
const makeSelectApplicationStatuses = () => createSelector(selectGlobal, (globalState) => globalState.applicationStatuses);
const makeSelectApplicationStatusById = (id: number) =>
  createSelector(makeSelectApplicationStatuses(), (statuses) => statuses.find((x) => x.id === id));

const makeSelectRoleUserActions = () => createSelector(selectGlobal, (globalState) => globalState.roleUserActions);
const makeSelectRoleUserActionsByIds = (ids: Array<number>) =>
  createSelector(makeSelectRoleUserActions(), (actions) => actions.filter((x) => ids.indexOf(x.id) !== -1));

const makeSelectRoleUserActionSettings = () => createSelector(selectGlobal, (globalState) => globalState.roleUserActionSettings);

const makeSelectCountries = () => createSelector(selectGlobal, (globalState) => globalState.countries);
const makeSelectCountryById = (id: number) => createSelector(makeSelectCountries(), (countries) => countries.find((x) => x.id === id));

const makeSelectLocationTypes = () => createSelector(selectGlobal, (globalState) => globalState.locationTypes);
const makeSelectLocationTypeById = (id: number) =>
  createSelector(makeSelectLocationTypes(), (locationTypes) => locationTypes.find((x) => x.id === id));

const makeSelectCompanyTypes = () => createSelector(selectGlobal, (globalState) => globalState.companyTypes);

const makeSelectMessageConfigShow = () => createSelector(selectGlobal, (globalState) => globalState.messageConfigShow);

const makeSelectWaypointLocations = () => createSelector(selectGlobal, (globalState) => globalState.waypointLocations);
const makeSelectBorderCrossingLocations = () => createSelector(selectGlobal, (globalState) => globalState.borderCrossingLocations);

const makeSelectUserTypes = () => createSelector(selectGlobal, (globalState) => globalState.userTypes);

const makeSelectApplicationServices = () => createSelector(selectGlobal, (globalState) => globalState.applicationServices);

const makeSelectHost = () => createSelector(selectGlobal, (globalState) => globalState.host);
const makeSelectBitrixOptions = () => createSelector(selectGlobal, (globalState) => globalState.bitrixOptions);

const makeSelectCarriers = () => createSelector(selectGlobal, (globalState) => globalState.carriers);
const makeSelectBitrixCarriers = () => createSelector(selectGlobal, (globalState) => globalState.bitrixCarriers);

const makeSelectIncotermsTypes = () => createSelector(selectGlobal, (globalState) => globalState.incotermsTypes);

const makeSelectLanguage = () => createSelector(selectGlobal, (globalState) => globalState.language);

export {
  makeSelectLoading,
  makeSelectUserInfo,
  makeSelectIsAuthModalVisible,
  makeSelectIsContactModalVisible,
  makeSelectIsContactModalLoading,
  makeSelectCurrencies,
  makeSelectCurrencyById,
  makeSelectTransportCharacteristics,
  makeSelectTransportCharacteristicsOptions,
  makeSelectTransportationTypes,
  makeSelectAdditionalJobs,
  makeSelectDocumentTypes,
  makeSelectDocumentTypeById,
  makeSelectApplicationStatuses,
  makeSelectApplicationStatusById,
  makeSelectCurrencySymbol,
  makeSelectCurrencyName,
  makeSelectCurrenciesOptions,
  makeSelectInitialLoadingError,
  makeSelectTransportCharacteristicById,
  makeSelectTransportationTypesByIds,
  makeSelectTransportationTypeTypeById,
  makeSelectTransportationTypeNameById,
  makeSelectTransportationTypesOptions,
  makeSelectMessageConfigShow,
  makeSelectRoleUserActions,
  makeSelectRoleUserActionsByIds,
  makeSelectRoleUserActionSettings,
  makeSelectContainerTypeOptions,
  makeSelectCountries,
  makeSelectCountryById,
  makeSelectLocationTypes,
  makeSelectLocationTypeById,
  makeSelectIsContainerTransportation,
  makeSelectCompanyTypes,
  makeSelectIsShowCarrierInfo,
  makeSelectContainerIssuingAdditionalJob,
  makeSelectWaypointLocations,
  makeSelectUserTypes,
  makeSelectBorderCrossingLocations,
  makeSelectApplicationServices,
  makeSelectHost,
  makeSelectBitrixOptions,
  makeSelectCarriers,
  makeSelectBitrixCarriers,
  makeSelectIncotermsTypes,
  makeSelectLanguage,
};
